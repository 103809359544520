import { useEffect } from 'react'

import * as Sentry from '@sentry/remix'
import { useLocation, useMatches } from 'react-router'

const disallowedSentryRoutes = ['routes/healthcheck', 'socket', 'favicon.ico']

// Set up a custom sampler function that checks the current route
const shouldSampleFullSession = () => {
  const currentPath = window.location.pathname
  if (currentPath.includes('/t/')) return true
  if (currentPath.includes('/account/')) return true
  return false
}

export function SentryInit() {
  if (process.env.NODE_ENV === 'development') return

  const replaysSessionSampleRate = shouldSampleFullSession() ? 1.0 : 0.1
  Sentry.init({
    attachStacktrace: true,
    dsn: getSentryDsn(),
    // Session Replay
    replaysSessionSampleRate,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracesSampler: (samplingContext) => {
      if (
        disallowedSentryRoutes.includes(samplingContext.transactionContext.name)
      ) {
        // Drop this transaction, by setting its sample rate to 0%
        return 0
      }
      // Default sample rate for all others (replaces tracesSampleRate)
      return 1
    },
    environment: getSentryClientEnvironment(),
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
      }),
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
  })
}

function getSentryDsn() {
  const prod =
    'https://5314fb6e0e78e14036a97c976c97aa65@o4506924570836992.ingest.us.sentry.io/4506924571295744'
  return prod
}

function getSentryClientEnvironment() {
  if (window.location.host.includes('localhost')) return 'development'
  // if (NODE_ENV === 'test') return 'test'
  if (window.location.host.includes('staging')) return 'staging'

  return 'production'
}
